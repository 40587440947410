.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979ff;
  border-radius: 8px 8px 0 0;
  height: 60px;
  width: 100%;
}

.leftInnerContainer {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 3%;
  color: white;
}

.rightInnerContainer {
  margin-right: 5%;
}

.onlineIcon {
  flex: 0;
  margin-right: 3%;
}

.infoBarSeparator {
  flex: 0;
  margin-left: 2%;
}

.infoBarUsers {
  flex: 1;
  margin-left: 2%;
  margin-right: 5%;
}
